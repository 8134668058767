<template>
    <section id="hero-header" style="padding: 10vh 0">
        <div class="container">
            <div class="header-content col-md-12">
                <div class="row fade-up" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="800"
                    data-aos-once="true">
                    <div class="col-md-7 align-self-center">
                        <h1>Ordinal Monkey Business</h1>
                        <p><b>One of the first ordinals project on Bitcoin</b></p>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item list-group-item-dark">107 Pixelated Monkeys</li>
                            <li class="list-group-item list-group-item-dark">Bitcoin Ordinal OG Builders</li>
                            <li class="list-group-item list-group-item-dark">Holders Airdrops</li>
                        </ul>
                        <br>
                        <div class="d-flex" style="column-gap: 20px; flex-wrap: wrap; row-gap: 10px;">
                            <a href="https://twitter.com/NatmonkeysDMT" target="_blank" class="btn btn-primary"
                                role="button">Twitter <i class="fab fa-twitter"></i></a>
                            <a href="https://discord.gg/ynqBbq7EbF" target="_blank" class="btn btn-discord"
                                role="button">Discord <i class="fab fa-discord"></i></a>
                            <a href="/collection" class="btn btn-warning" role="button">Collection</a>
                        </div>
                    </div>
                    <div class="d-none col-md-5 align-self-center d-md-flex justify-content-center">
                        <img src="https://ordinals.com/content/a6d26173bf0a20b1f3ef1876eabc2c5976455c9bc88ef83e7b2b27dc235983c4i0"
                            class="profile-img img-fluid" alt="OMB king">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="about">
        <div class="container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="800"
            data-aos-once="true">
            <div class="col-md-12">
                <div class="row justify-content-between align-items-center">
                    <div class="d-none d-lg-block col-lg-4">
                        <div class="div-img-bg">
                            <div class="about-img">
                                <img src="https://ordinals.com/content/080e2e5c4f98f08bc567c137f8f2f1df28ce59d85e8085016f17d351f410bfc5i0"
                                    class="img-fluid" alt="me">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="about-descr">
                            <div class="section-title text-center">
                                <h2>About</h2>
                            </div>
                            <p class="p-heading">Ordinal Monkey Business is a collection of 107, 24 by 24 Pixelated
                                monkeys brought to the Bitcoin Blockchain as Ordinal inscriptions. Each one representing
                                a significant piece of BTC history.</p>
                            <p></p>
                            <a href="https://natmonkeys.gitbook.io/ombiz" target="_blank"
                                class="btn btn-warning" role="button"><i class="fas fa-scroll"></i> Whitepaper</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="services">
        <div class="container text-center" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="800"
            data-aos-once="true">
            <div class="section-title text-center">
                <h2>Services</h2>
            </div>
            <div style="display: grid; grid-template-columns: repeat( auto-fit, minmax(300px, 1fr));
grid-gap: 20px; grid-template-rows: repeat( auto-fit, minmax(250px, 1fr) )">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Ordinals Onboarding</h5>
                        <hr>
                        <p class="card-text">Complete assistance on boarding into ordinals. Wallet setup, node setup,
                            troubleshooting, and creating inscriptions. At absolutely no charge.</p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Art</h5>
                        <hr>
                        <p class="card-text">We offer the creation of individual art as well as entire
                            collections, generative and 1 of 1s. We work with reliable artists vetted by our team.</p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Full Project Launch</h5>
                        <hr>
                        <p class="card-text">Full project launch, we will be taking care of whatever is needed, from
                            conception, to art, inscribing, and minting. All done by us in conjunction with our partners.</p>
                    </div>
                </div>
            </div>
            <div class="mt-5 d-flex justify-content-center" style="column-gap: 20px;">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSebIkFCZIp0h04MEKlS24u7S9X3a7V8g-ej9KvF-q1pnZ70Hg/viewform?usp=sf_link"
                    target="_blank" class="btn btn-warning" role="button">Request a Service</a>
                <!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLSebIkFCZIp0h04MEKlS24u7S9X3a7V8g-ej9KvF-q1pnZ70Hg/viewform?usp=sf_link" target="_blank" class="btn btn-warning" role="button">Woek with us</a> -->
            </div>
        </div>
    </section>
    <section id="contact">
        <div class="container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="800"
            data-aos-once="true">
            <div class="section-title text-center mb-5">
                <h2>Join Our Community</h2>
            </div>
            <div class="row text-center">
                <div class="col-md-12">
                    <div>
                        <div class="icon-block pb-3">
                            <span class="icon-block__icon">
                                <span class="mbri-letter mbr-iconfont" media-simple="true"></span>
                            </span>
                            <h5>Come hang out in our Discord server and follow us on Twitter for latest news
                            </h5>
                        </div>
                        <div class="pb-3 d-flex flex-column align-items-center">
                            <p style="max-width: 800px;font-size: 1rem;"><em>For services, inquiries, or
                                    questions, please join our discord and open a ticket. Our team will be happy to help!</em></p>
                            <div class="d-flex align-content-center justify-content-center mt-5"
                                style="column-gap: 20px; flex-wrap: wrap; row-gap: 10px;">
                                <a href="https://twitter.com/NatmonkeysDMT" target="_blank" class="btn btn-primary"
                                    role="button">Twitter <i class="fab fa-twitter"></i></a>
                                <a href="https://discord.gg/ynqBbq7EbF" target="_blank" class="btn btn-discord"
                                    role="button">Discord <i class="fab fa-discord"></i></a>
                                <a href="/collection" class="btn btn-warning" role="button">Collection</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>

</style>